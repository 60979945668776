module.exports = {
  siteTitle: 'Saurabh Jindal | Startups, Product Management, Business Strategy, Sales',
  siteDescription:
    'Saurabh Jindal is an entrepreneur, currently based out of Paris(France). He has built web and mobile products, provided tech and digital consulting and sold products and services.',
  siteKeywords:
    'Saurabh Jindal, Saurabh, Jindal, saurabhjindal, jindalsaurabh, startup, product Management, sales, entrepreneur, consulting, technology, sales, busienss development, mentoring',
  siteUrl: 'https://saurabhjindal.com',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-175480393-1',
  googleVerification: '2N5f1QZZn3pBz5ZOM7BBuor3eBkBOqtBNyNx4ZKA__8',
  name: 'Saurabh Jindal',
  location: 'Paris, France',
  email: 'jindalsaurabh@gmail.com',
  github: 'https://github.com/jindalsaurabh',
  twitterHandle: '@jindalsaurabh',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/jindalsaurabh',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/jindalsaurabh',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/jindalsaurabh',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Education',
      url: '/#education',
    },
    {
      name: 'Other',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};

import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="S" transform="translate(11.000000, 5.000000)">
        <path
          d="

          m 33.730809,63.53282 c -2.321932,-0.312319 -4.198417,-0.837568 -6.189891,-1.732628 -3.3666,-1.513108 -3.255125,-1.21026 -1.989844,-5.405861 0.708808,-2.350371 1.186238,-3.532994 1.411447,-3.496242 0.187177,0.03054 0.983121,0.410745 1.768761,0.844886 2.425455,1.340298 4.979886,1.855743 8.587397,1.732799 3.665238,-0.124907 3.663972,-0.123979 3.669706,-2.684045 0.0032,-1.432489 -0.08407,-1.72591 -0.726416,-2.442089 -0.401531,-0.447691 -1.499642,-1.10679 -2.440247,-1.464661 -5.103511,-1.941746 -7.110711,-2.907229 -8.341473,-4.012334 -2.722073,-2.444161 -3.607148,-5.051691 -3.439541,-10.133277 0.07722,-2.34113 0.221274,-3.341368 0.631874,-4.387384 1.773468,-4.517976 4.995492,-6.482227 10.629318,-6.479982 3.182593,0.0012 4.786011,0.366736 7.677847,1.750009 l 1.9641,0.939502 -0.0257,2.888145 c -0.01414,1.588484 -0.148047,3.810865 -0.29759,4.938625 l -0.271894,2.050475 -2.904246,0.08845 -2.904247,0.08844 -0.13667,-1.192545 c -0.07517,-0.6559 -0.214545,-1.689391 -0.309735,-2.296648 L 39.9207,32.022351 37.778044,31.926672 c -2.692752,-0.120239 -3.59546,0.09929 -4.31191,1.048619 -0.731352,0.969076 -0.77253,2.877094 -0.08702,4.03186 0.550772,0.927786 2.17766,1.803082 4.756036,2.558822 6.744562,1.976892 9.06708,4.394404 9.768558,10.168108 0.463663,3.816289 -0.171819,7.11929 -1.843801,9.583413 -1.251258,1.844066 -2.76158,2.959786 -4.887202,3.610317 -1.562256,0.478117 -5.814919,0.823849 -7.441901,0.605009 z

          "
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
